import React, { useEffect, useState } from 'react';
import {
  ArrowContainer,
  Button,
  Container,
  Content,
  Month,
  Top,
  WeekLabel,
  WeekLabelContainer,
} from './styles';
import Day from './Components/Day';
import Week from './Components/Week';
import calendar from 'calendar-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import minMax from 'dayjs/plugin/minMax';
import isBetween from 'dayjs/plugin/isBetween';
import { IconSimpleArrow } from 'components/IconsView';
import { v4 as uuidv4 } from 'uuid';
import HolidaysI from 'data/types/Holidays.types';
import classNames from 'classnames';
import { convertTimezones, getDates } from 'utils/functions';
require('date-time-format-timezone');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(minMax);
dayjs.extend(isBetween);

interface Props {
  start: string;
  end: string;
  dateSelected: (date: string) => void;
  dateRange?: string[];
  holidays?: HolidaysI[];
  className?: string;
  allowDisabledArrow?: boolean;
  handleViewChange?: (month: number, year: number) => void;
  showHover?: boolean;
  allowWeekends?: boolean;
  allowWFHDays?: boolean;
  allowAllDays?: boolean;
  tz?: string;
}

interface DayI {
  customDate: string;
  date: Date;
  day: number;
  index: {
    day: number;
    week: number;
  };
  isInLastWeekOfPrimaryMonth: boolean;
  isInPrimaryMonth: boolean;
}

// match start and end dates to use single date mode

const CalendarView = ({
  dateSelected,
  start,
  end,
  dateRange,
  holidays,
  className,
  allowDisabledArrow,
  handleViewChange,
  showHover,
  allowWeekends,
  allowWFHDays,
  allowAllDays,
  tz = 'PST',
}: Props): JSX.Element => {
  const currentDate = dayjs.tz.guess();
  // eslint-disable-next-line
  const cal: any = calendar();
  const [startDate, setStartDate] = useState<string>(dayjs().format('l'));
  const [endDate, setEndDate] = useState<string>(dayjs().format('l'));
  const [viewing, setViewing] = useState({
    month: dayjs().month(),
    year: dayjs().year(),
  });
  const [monthDetails, setMonthDetails] = useState(
    cal.detailed(
      viewing.year,
      viewing.month,
      (data: {
        date: string | number | Date | dayjs.Dayjs | null | undefined;
      }) => {
        return Object.assign(
          {
            customDate: dayjs(data.date).format('l'),
          },
          data
        );
      }
    )
  );

  const [hoverDatesBetween, setHoverDatesBetween] = useState<string[]>([]);
  const [hoverDate, setHoverDate] = useState<string>('');

  useEffect(() => {
    setStartDate(start);
    setHoverDate('');
    setHoverDatesBetween([]);
  }, [start]);
  useEffect(() => {
    setEndDate(end);
    setHoverDate('');
    setHoverDatesBetween([]);
  }, [end]);

  const cycleMonth = (dir: number) => {
    // change displayed month
    let updatedMonth = viewing.month + dir;
    let updatedYear = viewing.year;
    if (updatedMonth < 0) {
      updatedMonth = 11;
      updatedYear -= 1;
    } else if (updatedMonth > 11) {
      updatedMonth = 0;
      updatedYear += 1;
    }
    setViewing({
      month: updatedMonth,
      year: updatedYear,
    });

    setMonthDetails(
      cal.detailed(updatedYear, updatedMonth, (data: { date: string }) => {
        return Object.assign(
          {
            customDate: dayjs(data.date).format('l'),
          },
          data
        );
      })
    );

    if (handleViewChange) {
      handleViewChange(updatedMonth, updatedYear);
    }
  };

  const handleHover = (date: string) => {
    // check if start date is selected
    if (dayjs(startDate).isSame(dayjs(date))) {
      setHoverDate('');
      setHoverDatesBetween([]);
    } else if (dayjs(startDate).isValid() && dayjs(endDate).isSame(startDate)) {
      //check to see if the date is after the start date
      let earlyDate = '';
      let lateDate = '';
      if (dayjs(date).isAfter(startDate)) {
        earlyDate = startDate;
        lateDate = date;
      } else {
        earlyDate = date;
        lateDate = startDate;
      }

      // get the dates between start and hover
      const range = getDates(
        dayjs(earlyDate, 'MM-DD-YYYY').format(),
        dayjs(lateDate, 'MM-DD-YYYY').format()
      );

      setHoverDatesBetween(range);
      setHoverDate(date);
    }
  };
  const renderDaysOfWeek = (week: DayI[]) => {
    // render days in a week
    return week.map((day) => {
      const mDayDate = dayjs(day.date);
      const isToday = dayjs().isSame(mDayDate, 'date');
      const isEndDay = !!(
        endDate && dayjs(endDate).format('l') === day.customDate
      );

      const hoverDateRangeFormatted = hoverDatesBetween?.map((date) => {
        return dayjs(date);
      });
      const isMinHover = hoverDateRangeFormatted
        ? dayjs.min(hoverDateRangeFormatted)
        : false;
      const isMaxHover = hoverDateRangeFormatted
        ? dayjs.max(hoverDateRangeFormatted)
        : false;

      let holidaySingle = false;
      let holidayStart = false;
      let holidayEnd = false;
      let holidayBetween = false;
      if (holidays) {
        for (let i = 0; i < holidays?.length; i++) {
          const holiday = holidays[i];
          if (
            dayjs(holiday.startDate).isSame(mDayDate, 'date') &&
            dayjs(holiday.endDate).isSame(mDayDate, 'date')
          ) {
            holidaySingle = true;
          } else if (
            dayjs(holiday.startDate).isSame(mDayDate, 'date') &&
            !holiday.endDate
          ) {
            holidaySingle = true;
          }
          if (
            dayjs(holiday.startDate).isSame(mDayDate, 'date') &&
            !dayjs(holiday.endDate).isSame(mDayDate, 'date')
          ) {
            holidayStart = true;
          } else if (
            !dayjs(holiday.startDate).isSame(mDayDate, 'date') &&
            dayjs(holiday.endDate).isSame(mDayDate, 'date')
          ) {
            holidayEnd = true;
          } else if (
            dayjs(mDayDate).isBetween(holiday.startDate, holiday.endDate)
          ) {
            holidayBetween = true;
          }
        }
      }
      //* is today or previous or after 10pm PST
      const isPrevDay =
        isToday ||
        dayjs(mDayDate).isBefore(dayjs()) ||
        (dayjs().tz(convertTimezones(tz)).hour() >= 20 &&
          dayjs(mDayDate).isSame(dayjs().add(1, 'day'), 'date'));
      return (
        <Day
          key={`${uuidv4()}-${day.date.toISOString()}`}
          dayClicked={() => dateSelected(day.customDate)}
          dayHovered={() => showHover && handleHover(day.customDate)}
          number={day.day}
          currentDate={currentDate}
          isToday={isToday}
          outsideViewingMonth={!day.isInPrimaryMonth}
          startDay={
            !!(startDate && dayjs(startDate).format('l') === day.customDate)
          }
          endDay={isEndDay}
          betweenDay={dateRange && dateRange.includes(day.customDate)}
          isHolidaySingle={holidaySingle}
          isHolidayStart={holidayStart}
          isHolidayEnd={holidayEnd}
          isHolidayBetween={holidayBetween}
          isWeekend={day.index.day === 0 || day.index.day === 6}
          isHovered={!!(hoverDate && hoverDate === day.customDate)}
          isHoveredBetween={
            hoverDatesBetween && hoverDatesBetween.includes(day.customDate)
          }
          isHoveredMin={
            !!(isMinHover && dayjs(isMinHover).format('l') === day.customDate)
          }
          isHoveredMax={
            !!(isMaxHover && dayjs(isMaxHover).format('l') === day.customDate)
          }
          isPrevDay={isPrevDay}
          isWFHDay={day.index.day === 1 || day.index.day === 5}
          allowWeekends={allowWeekends}
          allowWFHDays={allowWFHDays}
          allowAllDays={allowAllDays}
        />
      );
    });
  };

  const renderWeeksOfMonth = (daysOfMonthData: [DayI[]]) => {
    // render weeks in a month
    return daysOfMonthData.map((daysOfWeekData) => {
      return (
        <Week key={daysOfWeekData[0].date.toISOString()}>
          {renderDaysOfWeek(daysOfWeekData)}
        </Week>
      );
    });
  };

  return (
    <Container
      onMouseLeave={() => {
        setHoverDate('');
        setHoverDatesBetween([]);
      }}
      data-testid="calendar"
      className={classNames('calendar', className)}
    >
      <Top>
        <Month>
          {dayjs().month(viewing.month).format('MMMM')} {viewing.year}
        </Month>
        <ArrowContainer>
          <Button
            type="button"
            side="left"
            disabled={
              allowDisabledArrow &&
              dayjs().month() === viewing.month &&
              dayjs().year() === viewing.year
            }
            aria-label="arrow-left"
            onClick={() => cycleMonth(-1)}
            className={classNames({
              hide:
                dayjs().month() === viewing.month &&
                dayjs().year() === viewing.year,
            })}
          >
            <IconSimpleArrow />
          </Button>
          <Button
            type="button"
            side="right"
            aria-label="arrow-right"
            onClick={() => cycleMonth(1)}
          >
            <IconSimpleArrow />
          </Button>
        </ArrowContainer>
      </Top>
      <Content>
        <WeekLabelContainer>
          <WeekLabel>
            <span>S</span>
          </WeekLabel>
          <WeekLabel>
            <span>M</span>
          </WeekLabel>
          <WeekLabel>
            <span>T</span>
          </WeekLabel>
          <WeekLabel>
            <span>W</span>
          </WeekLabel>
          <WeekLabel>
            <span>T</span>
          </WeekLabel>
          <WeekLabel>
            <span>F</span>
          </WeekLabel>
          <WeekLabel>
            <span>S</span>
          </WeekLabel>
        </WeekLabelContainer>
        {renderWeeksOfMonth(monthDetails.calendar)}
      </Content>
    </Container>
  );
};

export default CalendarView;
