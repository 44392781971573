import classNames from 'classnames';
import React from 'react';
import { DayContainer } from './styles';
import isTouch from '@three11/istouch';

interface Props {
  number: number;
  isToday?: boolean;
  outsideViewingMonth?: boolean;
  dayClicked: () => void;
  dayHovered: () => void;
  startDay?: boolean;
  endDay?: boolean;
  betweenDay?: boolean;
  currentDate?: string;
  isHolidaySingle?: boolean;
  isHolidayStart?: boolean;
  isHolidayEnd?: boolean;
  isHolidayBetween?: boolean;
  isWeekend?: boolean;
  isHovered?: boolean;
  isHoveredBetween?: boolean;
  isHoveredMin?: boolean;
  isHoveredMax?: boolean;
  isPrevDay?: boolean;
  allowWeekends?: boolean;
  allowAllDays?: boolean;
  allowWFHDays?: boolean;
  isWFHDay?: boolean;
}

const Day = ({
  number,
  isToday,
  outsideViewingMonth,
  dayClicked,
  dayHovered,
  startDay,
  endDay,
  betweenDay,
  isHolidaySingle,
  isHolidayStart,
  isHolidayEnd,
  isHolidayBetween,
  isWeekend,
  isHovered,
  isHoveredBetween,
  isHoveredMin,
  isHoveredMax,
  isPrevDay,
  allowWeekends,
  allowWFHDays,
  allowAllDays,
  isWFHDay,
}: Props): JSX.Element => {
  const isValidWeekDay = (): boolean => {
    if (allowAllDays) {
      return true;
    }
    if (!allowWFHDays && allowWeekends) {
      return (
        !isWFHDay &&
        !isHolidaySingle &&
        !isHolidayStart &&
        !isHolidayEnd &&
        !isHolidayBetween
      );
    }
    if (allowWFHDays && !allowWeekends) {
      return (
        !isWeekend &&
        !isHolidaySingle &&
        !isHolidayStart &&
        !isHolidayEnd &&
        !isHolidayBetween
      );
    }
    if (allowWeekends && allowWFHDays) {
      return (
        !isHolidaySingle &&
        !isHolidayStart &&
        !isHolidayEnd &&
        !isHolidayBetween
      );
    }
    return (
      !isWeekend &&
      !isWFHDay &&
      !isHolidaySingle &&
      !isHolidayStart &&
      !isHolidayEnd &&
      !isHolidayBetween
    );
  };

  return (
    <DayContainer
      className={classNames({
        today: isToday,
        'outside-viewing-month': outsideViewingMonth,
        'start-day': startDay,
        'end-day': endDay,
        'between-day': betweenDay,
        'holiday-single': isHolidaySingle,
        'holiday-start': isHolidayStart,
        'holiday-end': isHolidayEnd,
        'holiday-between': isHolidayBetween,
        ...(allowWeekends ? null : { weekend: isWeekend }),
        ...(allowWFHDays ? null : { wfh: isWFHDay }),
        'hover-start': isHovered,
        'hover-between': isHoveredBetween,
        'hover-min': isHoveredMin,
        'hover-max': isHoveredMax,
        'previous-day': isPrevDay,
      })}
    >
      <span
        onClick={() => {
          if (isValidWeekDay()) {
            dayClicked();
          }
        }}
        onMouseEnter={() => {
          if (isValidWeekDay() && !isTouch) {
            dayHovered();
          }
        }}
      >
        {number}
      </span>
    </DayContainer>
  );
};

export default Day;
