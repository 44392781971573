import React, { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import * as mutations from 'graphql/mutations';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isoWeek from 'dayjs/plugin/isoWeek';
import minMax from 'dayjs/plugin/minMax';
import duration from 'dayjs/plugin/duration';
import {
  AnotherOne,
  Calendar,
  Checkbox,
  Container,
  Content,
  Dates,
  Description,
  Dot,
  Error,
  HolidayLabel,
  Input,
  Label,
  Preview,
  Radio,
  RadioGroup,
  Request,
  StepLabel,
  Steps,
  Submission,
  SubmitButton,
  SubmitLoader,
  TextArea,
  Times,
  Title,
  WFHAlert,
} from './styles';
import { getDates, validateTime } from 'utils/functions';
import TimeSelector from '../FormComponents/TimeSelector';
import { H3 } from 'shared/typography';
import classNames from 'classnames';
import HolidaysI from 'data/types/Holidays.types';
import { UserI } from 'data/types/User.types';
import { IconArrow } from 'components/IconsView';
import { PacmanLoader } from 'react-spinners';
import UnsavedModalRoute from 'components/UnsavedModalRoute';

require('date-time-format-timezone');

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isoWeek);
dayjs.extend(minMax);
dayjs.extend(duration);

interface PropsI {
  user: UserI;
  holidays?: HolidaysI[];
}

interface OverlapHolidayI {
  name: string;
  startDate: string;
  endDate: string;
  weekdays: number;
}

const EMAIL_PTO = gql(mutations.emailPTO);
const WFH_MINIMUM_HRS = 2;

const PTOView = ({ user, holidays }: PropsI): JSX.Element => {
  const [emailPTO] = useMutation(EMAIL_PTO);
  const methods = useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const [startDate, setStartDateState] = useState<string>('');
  const startDateRef = useRef(startDate);
  const setStartDate = (date: string) => {
    setStartDateState(date);
    startDateRef.current = date;
  };
  const [endDate, setEndDateState] = useState<string>('');
  const endDateRef = useRef(endDate);
  const setEndDate = (date: string) => {
    setEndDateState(date);
    endDateRef.current = date;
  };
  const [datesBetween, setDatesBetween] = useState<string[]>([]);
  const [calendarView, setCalendarView] = useState({
    month: dayjs().month(),
    year: dayjs().year(),
  });
  const [validDates, setValidDates] = useState({ start: true, end: true });
  const [currentFocus, setCurrentFocus] = useState('start'); // switch between what date is saved when in range mode
  const [errors, setErrors] = useState<{
    date: boolean;
    start: boolean;
    startMissing: boolean;
    end: boolean;
    endMissing: boolean;
    time: boolean;
    missingDates: boolean;
    request: boolean;
    startTime: boolean;
    sameTime: boolean;
    wfhShort: boolean;
    wfhLong: boolean;
  }>({
    date: false,
    start: false,
    startMissing: false,
    end: false,
    endMissing: false,
    time: false,
    missingDates: false,
    request: false,
    startTime: false,
    sameTime: false,
    wfhShort: false,
    wfhLong: false,
  });
  const [submitted, setSubmittedState] = useState(false);
  const submittedRef = useRef(submitted);
  const setSubmitted = (value: boolean) => {
    setSubmittedState(value);
    submittedRef.current = value;
  };
  const [request, setRequest] = useState('');

  const checkForm = (): boolean => {
    if (submitted) {
      return false;
    }
    if (startDateRef.current || endDateRef.current) {
      return true;
    }

    const startTime = methods.watch('startTime');
    const endTime = methods.watch('endTime');
    if (startTime || endTime) {
      return true;
    }

    const notes = methods.watch('notes');
    if (notes) {
      return true;
    }

    const partialDay = methods.watch('partialDay');
    if (partialDay) {
      return true;
    }

    if (request !== '') {
      return true;
    }

    return false;
  };

  useEffect(() => {
    return () => {
      setErrors({
        date: false,
        start: false,
        end: false,
        startMissing: false,
        endMissing: false,
        time: false,
        missingDates: false,
        request: false,
        startTime: false,
        sameTime: false,
        wfhShort: false,
        wfhLong: false,
      });
      setSubmitted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [alertWeekend, setAlertWeekend] = useState(0);
  const [alertWFH, setAlertWFH] = useState(0);
  const [alertPastDay, setAlertPastDay] = useState(false);
  const [alertHoliday, setAlertHoliday] = useState(0);

  const countWeekends = (rangeOfDates: string[]) => {
    let count = 0;
    rangeOfDates.map((date) => {
      if (dayjs(date).isoWeekday() === 6 || dayjs(date).isoWeekday() === 7) {
        count += 1;
      }
      return count;
    });
    return count;
  };

  const countWFHDays = (rangeOfDates: string[]) => {
    let count = 0;
    rangeOfDates.map((date) => {
      if (dayjs(date).isoWeekday() === 1 || dayjs(date).isoWeekday() === 5) {
        count += 1;
      }
      return count;
    });
    return count;
  };

  const dateRangeOverlaps = (
    a_start: string,
    a_end: string,
    b_start: string,
    b_end: string
  ) => {
    if (
      dayjs(a_start).isSameOrBefore(dayjs(b_start)) &&
      dayjs(b_start).isSameOrBefore(dayjs(a_end))
    )
      return true; // b starts in a
    if (
      dayjs(a_start).isSameOrBefore(dayjs(b_end)) &&
      dayjs(b_end).isSameOrBefore(dayjs(a_end))
    )
      return true; // b ends in a
    if (
      dayjs(b_start).isBefore(dayjs(a_start)) &&
      dayjs(a_end).isBefore(dayjs(b_end))
    )
      return true; // a in b
    return false;
  };

  useEffect(() => {
    // get dates between start and end
    const range = getDates(
      dayjs(startDate, 'MM-DD-YYYY').format(),
      dayjs(endDate, 'MM-DD-YYYY').format()
    );

    setDatesBetween(range);

    if (errors.missingDates && startDate && endDate) {
      setErrors((prev) => {
        return {
          ...prev,
          missingDates: false,
        };
      });
    }

    // check if date is in the past
    setAlertPastDay(
      dayjs(startDate).isBefore(dayjs(), 'date') ||
        dayjs(endDate).isBefore(dayjs(), 'date')
    );

    // count weekend dates
    setAlertWeekend(countWeekends(range));

    // check if there are holidays
    const start = dayjs(startDate).format();
    const end = dayjs(endDate).format();
    let holidayDays = 0;
    if (holidays && holidays.length > 0) {
      holidays.map((holiday) => {
        const holidayStart = dayjs(holiday.startDate).format();
        const holidayEnd = dayjs(holiday.endDate).format();
        // if the holiday is in the range
        if (
          dateRangeOverlaps(holidayStart, holidayEnd, start, end) ||
          dateRangeOverlaps(start, end, holidayStart, holidayEnd)
        ) {
          //check if holiday is a single or multi day
          if (
            dayjs(holidayStart).isSame(dayjs(holidayEnd), 'date') ||
            (dayjs(holidayStart).isValid() && !dayjs(holidayEnd).isValid())
          ) {
            holidayDays += 1;
          } else {
            //get overlap of holiday and selected days
            //the start of overlap should be the biggest start for holiday and selected
            const overlapStart = dayjs
              .max(dayjs(start), dayjs(holidayStart))
              .format();

            //the end of overlap should be the smallest end for holiday and selected
            const overlapEnd = dayjs
              .min(dayjs(end), dayjs(holidayEnd))
              .format();

            //get in between days then count the weekdays
            const overlapRange = getDates(overlapStart, overlapEnd);

            holidayDays += overlapRange.length;
          }
        }
      });
    }
    setAlertHoliday(holidayDays);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const handleDateSelected = (date: string) => {
    setErrors((prev) => {
      return { ...prev, date: false, start: false, end: false };
    });
    //logic for selection based off google flights calendar
    if (currentFocus === 'start') {
      // update start
      setStartDate(date);
      // set end so that its a single day
      setEndDate(date);
    } else {
      if (dayjs(date).isBefore(startDate)) {
        // check if selected end is before start
        // if it is set it as the start date and move old start to end
        setStartDate(date);
        setCurrentFocus('start');
        return;
      }
      // update end
      setEndDate(date);
    }
    setCurrentFocus(currentFocus === 'start' ? 'end' : 'start'); // switch between start and end
  };

  useEffect(() => {
    const tempErrors = errors;
    tempErrors.time = false;
    tempErrors.sameTime = false;
    tempErrors.startTime = false;
    const startTime = methods.watch('startTime');
    const endTime = methods.watch('endTime');
    const useTime = methods.watch('partialDay');
    const validStart = validateTime(startTime);
    const validEnd = validateTime(endTime);

    if (useTime) {
      const start = dayjs(`${startDate} ${startTime}`);
      const end = dayjs(`${endDate} ${endTime}`);
      //check if its a single day
      if (startDate === endDate) {
        if (
          startTime &&
          endTime &&
          validStart &&
          validEnd &&
          dayjs(start).isAfter(end)
        ) {
          tempErrors.time = true;
        } else {
          tempErrors.time = false;
        }
      }

      if (
        startTime &&
        endTime &&
        validStart &&
        validEnd &&
        startTime === endTime
      ) {
        tempErrors.sameTime = true;
      } else {
        tempErrors.sameTime = false;
      }
    }
    setErrors({ ...tempErrors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch('startTime'), methods.watch('endTime')]);

  useEffect(() => {
    const useTime = methods.watch('partialDay');
    if (!useTime) {
      // * if partialDay is unselected then remove all time errors and reset time values
      setErrors({ ...errors, time: false, sameTime: false, startTime: false });
      methods.resetField('startTime');
      methods.resetField('endTime');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch('partialDay')]);

  const countWeekdays = (rangeOfDates: string[]) => {
    let count = 0;
    // const newRangeOfDates = [...rangeOfDates];
    rangeOfDates.map((date) => {
      if (dayjs(date).isoWeekday() !== 6 && dayjs(date).isoWeekday() !== 7) {
        count += 1;
      }
      return count;
    });
    return count;
  };

  const countOfficeDays = (rangeOfDates: string[]) => {
    let count = 0;
    rangeOfDates.map((date) => {
      if (
        dayjs(date).isoWeekday() === 2 ||
        dayjs(date).isoWeekday() === 3 ||
        dayjs(date).isoWeekday() === 4
      ) {
        count += 1;
      }
      return count;
    });
    return count;
  };

  const watchPartialDay = methods.watch('partialDay');
  const watchStartTime = methods.watch('startTime');
  const watchEndTime = methods.watch('endTime');

  const formatTime = (timeString: string) => {
    const [datePart, timePart] = timeString.split(' ');
    const [year, month, day]: any = datePart.split('-');
    const [hour, minute]: any = timePart.split(':');
    const isPM = timePart.includes('PM');

    if (minute !== undefined) {
      const dateObj = new Date(year, month - 1, day, hour, minute);
      if (isPM && hour < 12) {
        dateObj.setHours(dateObj.getHours() + 12);
      }
      const isoString = dateObj.toISOString();
      return isoString;
    }
    return '';
  };

  useEffect(() => {
    console.log('request ', request, ' triggered!');
    if (request === 'WFH') {
      // check if more than or equal to WFH_MINIMUM_HRS
      if (watchPartialDay) {
        const time1String = `2023-03-16 ${watchStartTime}`;
        const time2String = `2023-03-16 ${watchEndTime}`;
        console.log('date 1 string: ', time1String);
        console.log('date 2 string: ', time2String);

        const time1Iso: string = formatTime(time1String);
        const time2Iso: string = formatTime(time2String);

        console.log('time1Iso: ', time1Iso);
        console.log('time2Iso: ', time2Iso);

        const formattedDate1 = new Date(time1Iso);
        const formattedDate2 = new Date(time2Iso);

        console.log('formattedDate1: ', formattedDate1);
        console.log('formattedDate2: ', formattedDate2);

        const time1 = dayjs(formattedDate1);
        const time2 = dayjs(formattedDate2);
        console.log('time1: ', time1);
        console.log('time2: ', time2);

        const timeDuration = dayjs.duration(time2.diff(time1));

        const hours = timeDuration.asHours();

        console.log(
          dayjs(startDate).isSame(dayjs(endDate)),
          hours,
          hours < WFH_MINIMUM_HRS
        );

        if (
          dayjs(startDate).isSame(dayjs(endDate)) &&
          hours > 0 &&
          hours < WFH_MINIMUM_HRS
        ) {
          setErrors((prev) => {
            return { ...prev, wfhShort: true };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, wfhShort: false };
          });
        }
      }
      const range = getDates(
        dayjs(startDate, 'MM-DD-YYYY').format(),
        dayjs(endDate, 'MM-DD-YYYY').format()
      );

      let wfhDayCount = countWFHDays(range);
      if (holidays && holidays.length > 0) {
        holidays.map((holiday) => {
          const holidayStart = dayjs(holiday.startDate).format();
          const holidayEnd = dayjs(holiday.endDate).format();
          // if the holiday is in the range
          if (
            dateRangeOverlaps(holidayStart, holidayEnd, startDate, endDate) ||
            dateRangeOverlaps(startDate, endDate, holidayStart, holidayEnd)
          ) {
            //check if holiday is a single or multi day
            if (
              (dayjs(holidayStart).isSame(dayjs(holidayEnd), 'date') ||
                (dayjs(holidayStart).isValid() &&
                  !dayjs(holidayEnd).isValid())) &&
              (dayjs(holidayStart).isoWeekday() === 1 ||
                dayjs(holidayStart).isoWeekday() === 5)
            ) {
              wfhDayCount -= 1;
            } else {
              //get overlap of holiday and selected days
              //the start of overlap should be the biggest start for holiday and selected
              const overlapStart = dayjs
                .max(dayjs(startDate), dayjs(holidayStart))
                .format();

              //the end of overlap should be the smallest end for holiday and selected
              const overlapEnd = dayjs
                .min(dayjs(endDate), dayjs(holidayEnd))
                .format();

              //get in between days then count the weekdays
              const overlapRange = getDates(overlapStart, overlapEnd);

              wfhDayCount -= countWFHDays(overlapRange);
            }
          }
        });
      }
      setAlertWFH(wfhDayCount);
      //check if less than or equal to 3 in office days
      const workCount = countOfficeDays(range);
      console.log('workCount', workCount);
      if (workCount > 3) {
        setErrors((prev) => {
          return { ...prev, wfhLong: true };
        });
      } else {
        setErrors((prev) => {
          return { ...prev, wfhLong: false };
        });
      }
    } else {
      setErrors((prev) => {
        return { ...prev, wfhLong: false, wfhShort: false };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, watchPartialDay, watchStartTime, watchEndTime, datesBetween]);

  const handleReset = () => {
    // reset form (does almost nothing)
    methods.reset();
    const formPTO: HTMLFormElement | null = document.querySelector('#pto-form');
    formPTO?.reset();

    // reset dates
    setStartDate('');
    setEndDate('');

    //reset partial day
    const partialDay: HTMLInputElement | null =
      document.querySelector('#partialday');
    if (partialDay) {
      partialDay.checked = false;
    }

    // reset times
    const startTime: HTMLInputElement | null =
      document.querySelector('#startTime');
    // const startTimeOptions: NodeListOf<HTMLInputElement> =
    //   document.querySelectorAll('#startTime option');
    if (startTime) {
      startTime.value = '';
    }
    const endTime: HTMLInputElement | null = document.querySelector('#endTime');
    // const endTimeOptions: NodeListOf<HTMLInputElement> =
    //   document.querySelectorAll('#endTime option');
    if (endTime) {
      endTime.value = '';
    }

    // reset notes
    const notes: HTMLTextAreaElement | null = document.querySelector('#notes');
    if (notes) {
      notes.value = '';
    }

    setSubmitting(false);
    setSubmitted(false);
    setRequest('');
    setCurrentFocus('start');

    methods.reset();
  };

  const renderHolidayList = (): JSX.Element => {
    if (holidays) {
      const currentHolidays = holidays.filter((holiday) => {
        // check holiday start or end is this month and year
        return (
          (dayjs(holiday.startDate).month() === calendarView.month &&
            dayjs(holiday.startDate).year() === calendarView.year) ||
          (dayjs(holiday.endDate).month() === calendarView.month &&
            dayjs(holiday.endDate).year() === calendarView.year)
        );
      });

      if (currentHolidays.length > 0) {
        const holidayList = currentHolidays.map((holiday) => {
          return (
            <HolidayLabel key={holiday.id}>
              {dayjs(holiday.startDate).isSame(
                dayjs(holiday.endDate),
                'date'
              ) ||
              (dayjs(holiday.startDate).isValid() &&
                !dayjs(holiday.endDate).isValid())
                ? `${dayjs(holiday.startDate).format('MMMM D')}: ${
                    holiday.title
                  }`
                : `${dayjs(holiday.startDate).format('MMMM D')} - ${dayjs(
                    holiday.endDate
                  ).format('MMMM D')}: ${holiday.title}`}
            </HolidayLabel>
          );
        });
        return <>{holidayList}</>;
      }
    }
    return <></>;
  };

  return (
    <Container className="pto">
      <UnsavedModalRoute
        content="Warning: Your progress will not be saved"
        title="Are you sure you want to leave this page?"
        type="page"
        isBlocked={checkForm()}
      />
      <FormProvider {...methods}>
        <Content
          id="pto-form"
          className={classNames({ active: !submitted })}
          onSubmit={methods.handleSubmit(async (input) => {
            console.log('input', input);
            let hasErrors = false;
            if (
              Object.values(errors).some(Boolean) ||
              !validDates.start ||
              !validDates.end
            ) {
              return;
            } else {
              const tempErrors: {
                date?: boolean;
                start?: boolean;
                startMissing?: boolean;
                end?: boolean;
                endMissing?: boolean;
                time?: boolean;
                missingDates?: boolean;
                request?: boolean;
                startTime?: boolean;
                sameTime?: boolean;
                wfhShort?: boolean;
                wfhLong?: boolean;
              } = {};

              // check if partial day is selected but no start time was selected
              if (input.partialDay && !input.startTime) {
                tempErrors.startTime = true;
                hasErrors = true;
              }

              if (request === '') {
                tempErrors.request = true;
                hasErrors = true;
              }

              if (!startDate && !endDate) {
                tempErrors.missingDates = true;
                hasErrors = true;
              }

              //check dates are valid
              if (!(dayjs(startDate).isValid() && startDate)) {
                tempErrors.start = true;
                hasErrors = true;
              }
              if (!(dayjs(endDate).isValid() && endDate)) {
                tempErrors.end = true;
                hasErrors = true;
              }
              if (
                !(
                  dayjs(startDate).isSameOrBefore(endDate) &&
                  startDate &&
                  endDate
                )
              ) {
                tempErrors.date = true;
                hasErrors = true;
              }

              //check if start and end times are valid
              if (input.partialDay && startDate === endDate) {
                const start = dayjs(`${startDate} ${input.startTime}`);
                const end = dayjs(`${endDate} ${input.endTime}`);
                if (input.endTime && start.isAfter(end)) {
                  tempErrors.time = true;
                  hasErrors = true;
                }
              }

              if (hasErrors) {
                setErrors({ ...errors, ...tempErrors });
              } else {
                // the dates are valid so lets submit
                setSubmitting(true);
                const selectedDaysStart = dayjs(startDate).format();
                const selectedDaysEnd = dayjs(endDate).format();
                const selectedRange = datesBetween;
                console.log('selectedRange', selectedRange);
                const overlappingHolidays: OverlapHolidayI[] = [];
                console.group('Selected Days');
                const selectedWeekdays = countWeekdays(selectedRange);
                let countedWFHDays = countWFHDays(selectedRange);
                console.log('selected days: ', selectedWeekdays);
                console.groupEnd();

                //selected days w/ weekends removed minus all holidays w/ weekends removed
                let totalDays = selectedWeekdays;

                // remove holidays from selected days
                if (holidays && holidays.length > 0) {
                  holidays.map((holiday) => {
                    const holidayStart = dayjs(holiday.startDate).format();
                    const holidayEnd = dayjs(holiday.endDate).format();
                    let overlapWeekdays = 0;
                    console.group(holiday.title);

                    // falls on holiday
                    if (
                      dateRangeOverlaps(
                        holidayStart,
                        holidayEnd,
                        selectedDaysStart,
                        selectedDaysEnd
                      ) ||
                      dateRangeOverlaps(
                        selectedDaysStart,
                        selectedDaysEnd,
                        holidayStart,
                        holidayEnd
                      )
                    ) {
                      console.log(holiday);

                      //check if holiday is a single or multi day
                      if (
                        dayjs(holidayStart).isSame(dayjs(holidayEnd), 'date') ||
                        (dayjs(holidayStart).isValid() &&
                          !dayjs(holidayEnd).isValid())
                      ) {
                        //single day holiday
                        console.log('holiday is a single day, greaaat');

                        //check if a weekday (just in case)
                        const holidayWeekNumber =
                          dayjs(holidayStart).isoWeekday();
                        console.log('holidays week number:', holidayWeekNumber);

                        // 6 === saturday, 7 === sunday
                        if (
                          holidayWeekNumber !== 6 &&
                          holidayWeekNumber !== 7
                        ) {
                          console.log(
                            'okay the single holiday is not a weekend date'
                          );
                          // since its a single day just take away one from the total days
                          totalDays -= 1;
                          overlapWeekdays = 1;

                          //check if this holiday is a wfh day, if it does we can minus one from the wfh days
                          if (
                            holidayWeekNumber === 1 ||
                            holidayWeekNumber === 5
                          ) {
                            countedWFHDays -= 1;
                          }
                        }
                      } else {
                        //multi day holiday
                        console.log('holiday is a multi day, not cool');

                        //get overlap of holiday and selected days
                        //the start of overlap should be the biggest start for holiday and selected
                        const overlapStart = dayjs
                          .max(dayjs(selectedDaysStart), dayjs(holidayStart))
                          .format();
                        console.log('overlapStart', overlapStart);

                        //the end of overlap should be the smallest end for holiday and selected
                        const overlapEnd = dayjs
                          .min(dayjs(selectedDaysEnd), dayjs(holidayEnd))
                          .format();
                        console.log('overlapEnd', overlapEnd);

                        //get in between days then count the weekdays
                        const overlapRange = getDates(overlapStart, overlapEnd);
                        console.log('overlapRange', overlapRange);
                        overlapWeekdays = countWeekdays(overlapRange);
                        console.log('overlapWeekdays', overlapWeekdays);

                        //minus the overlapping weekdays from the total days
                        totalDays -= overlapWeekdays;

                        //check if this holiday is a wfh day, if it does we can minus one from the wfh days
                        const overlapWorkFromHomeDays =
                          countWFHDays(overlapRange);
                        countedWFHDays -= overlapWorkFromHomeDays;
                      }

                      overlappingHolidays.push({
                        name: holiday.title,
                        startDate: dayjs(holidayStart).format('MM/DD/YY'),
                        endDate: dayjs(holidayEnd).format('MM/DD/YY'),
                        weekdays: overlapWeekdays,
                      });
                    }
                    console.groupEnd();
                  });
                }

                if (request === 'WFH' && countedWFHDays > 0) {
                  totalDays -= countedWFHDays;
                }

                console.log('total days: ', totalDays);

                // calc for partial days (fractions!!!)
                if (
                  input.partialDay &&
                  dayjs(startDate).isSame(dayjs(endDate))
                ) {
                  const hourDiff = Math.abs(
                    dayjs(`1/1/2020 ${input.startTime}`, 'l LT').diff(
                      dayjs(
                        `1/1/2020 ${
                          input.endTime ||
                          (user.timezone === 'PST' ? `6:00 PM` : `4:00 PM`)
                        }`,
                        'l LT'
                      ),
                      'minute'
                    ) / 60
                  );
                  totalDays = (hourDiff > 8 ? 8 : hourDiff) / 8;
                  console.log('changed total days: ', totalDays);
                } else if (
                  input.partialDay &&
                  !dayjs(startDate).isSame(dayjs(endDate))
                ) {
                  totalDays = totalDays - 1;
                  const hourDiff = Math.abs(
                    dayjs(`1/1/2020 ${input.startTime}`, 'l LT').diff(
                      dayjs(
                        user.timezone === 'PST'
                          ? `1/1/2020 6:00 PM`
                          : `1/1/2020 4:00 PM`,
                        'l LT'
                      ),
                      'minute'
                    ) / 60
                  );
                  console.log('hourDiff', hourDiff);
                  totalDays += (hourDiff > 8 ? 8 : hourDiff) / 8;
                  console.log('changed total days: ', totalDays);
                }

                if (totalDays === 0) {
                  totalDays = 1;
                }

                const subject = input.partialDay
                  ? `${request} Request - ${user.name} | ${startDate} - ${endDate}`
                  : `${request} Request - ${
                      user.name
                    } | ${startDate} - ${endDate} | ${totalDays} day${
                      dayjs(startDate).isSame(dayjs(endDate)) ? '' : 's'
                    }`;

                const compiledData = {
                  name: user.name,
                  email: user.email,
                  startDate: dayjs(startDate).format('MM/DD/YY'),
                  endDate: dayjs(endDate).format('MM/DD/YY'),
                  daysOff: totalDays,
                  notes: input.notes,
                  ...(input.partialDay ? { startTime: input.startTime } : null),
                  ...(input.partialDay &&
                  dayjs(startDate).isSame(dayjs(endDate))
                    ? { endTime: input.endTime }
                    : null),
                  subject,
                  type: request.toLowerCase(),
                  timezone: user.timezone,
                  partialDay: input.partialDay ? true : false,
                };

                console.log('compiled data: ', compiledData);
                try {
                  const email = await emailPTO({
                    variables: { input: compiledData },
                  });
                  console.log('email', email);
                } catch (err) {
                  console.log('error', err);
                }

                // No errors so lets submit
                setSubmitted(true);
              }
            }
            setSubmitting(false);
          })}
        >
          <Steps>
            <StepLabel>Step 1</StepLabel>
            <Title>Select Your Request Type</Title>
            <RadioGroup>
              <Radio
                label="PTO"
                id="PTO"
                name="request"
                value="PTO"
                error={!!errors.request}
                onClick={() => {
                  setRequest('PTO');
                  setErrors((prev) => ({ ...prev, request: false }));
                }}
              ></Radio>
              <Radio
                label="WFH"
                id="WFH"
                name="request"
                value="WFH"
                error={!!errors.request}
                onClick={() => {
                  setRequest('WFH');
                  setErrors((prev) => ({ ...prev, request: false }));
                }}
              ></Radio>
            </RadioGroup>
            {errors.request && <Error>Please select a request type.</Error>}
            {request === 'WFH' && (
              <WFHAlert>
                {`WFH requests should be a minimum of ${WFH_MINIMUM_HRS} hours and not more than 3 days in a row`}
              </WFHAlert>
            )}
          </Steps>
          <Steps>
            <StepLabel>Step 2</StepLabel>
            <Title>
              Select Your Date(s)
              <Description className="description">
                Same day requests should go to{' '}
                <a href="mailto:ooto@fiveanddone.com">ooto@fiveanddone.com</a>
              </Description>
            </Title>
            <Calendar
              dateSelected={(date) => handleDateSelected(date)}
              start={startDate}
              end={endDate}
              dateRange={datesBetween}
              holidays={holidays}
              showHover={true}
              allowWFHDays={request !== 'WFH'}
              handleViewChange={(m, y) =>
                setCalendarView({ month: m, year: y })
              }
              tz={user?.timezone || 'PST'}
            />
            {renderHolidayList()}
            {errors.date && <Error>Check that dates are correct</Error>}
            {errors.end && <Error>Check that end date is correct</Error>}
            {errors.start && <Error>Check that start date is correct</Error>}
            {errors.endMissing && <Error>Please add an end date</Error>}
            {errors.startMissing && <Error>Please add a start date</Error>}
            {errors.missingDates && (
              <Error>Please select a start and end date.</Error>
            )}
            {errors.wfhLong && (
              <Error>
                WFH requests are limited to a maximum of 3 days in a row
              </Error>
            )}
            {errors.wfhShort && methods.watch('partialDay') && (
              <Error>{`WFH requests must be a minimum of ${WFH_MINIMUM_HRS} hours`}</Error>
            )}
            <Checkbox
              name="partialDay"
              label="Partial Day"
              error={methods.formState.errors?.singleDay}
              disabled={!startDate ? true : false}
            />
            {methods.watch('partialDay') && (
              <TimeSelector
                {...methods.register('startTime')}
                setValidDates={(error) =>
                  setValidDates((prev) => ({ ...prev, ...error }))
                }
                setError={(error) => {
                  // setTimeout(() => {
                  //   console.log('trigger wfhShort');
                  //   methods.trigger('wfhShort');
                  // }, 1000);
                  setErrors((prev) => ({ ...prev, ...error }));
                }}
                error={errors.time || errors.sameTime || errors.wfhShort}
                errorStart={errors.startTime}
                invalidStart={!validDates?.start}
                invalidEnd={!validDates?.end}
                multiDay={!dayjs(startDate).isSame(dayjs(endDate))}
                timezone={user.timezone || 'PST'}
              />
            )}
            {errors.startTime && <Error>Please select a Leaving time</Error>}
            {errors.time && (
              <Error>Returning time cannot occur before Leaving time</Error>
            )}
            {errors.sameTime && (
              <Error>Returning time cannot be the same as Leaving time</Error>
            )}
            {(!validDates?.start || !validDates?.end) && (
              <Error>Enter in a valid time</Error>
            )}
            <Preview>
              <Label>Your Request</Label>
              <Dates
                className={classNames({
                  hasData: startDate || endDate,
                })}
              >
                <span>
                  {dayjs(startDate).isValid()
                    ? dayjs(startDate).format('MM/DD/YY')
                    : 'Select Date(s) Above'}
                </span>
                {((!startDate && !endDate) || startDate !== endDate) &&
                  (dayjs(endDate).isValid() ? (
                    <>
                      <Dot />
                      <span>{dayjs(endDate).format('MM/DD/YY')}</span>
                    </>
                  ) : (
                    ''
                  ))}
              </Dates>
              {methods.watch('partialDay') && (
                <Times
                  className={classNames({
                    hasData:
                      methods.watch('startTime') || methods.watch('endTime'),
                  })}
                >
                  <span>
                    {methods.watch('startTime')
                      ? `${methods.watch('startTime')} ${user.timezone}`
                      : 'Select Time(s) Above'}
                  </span>
                  {dayjs(startDate).isSame(dayjs(endDate)) ? (
                    methods.watch('endTime') ? (
                      <>
                        <Dot />
                        <span>
                          {methods.watch('endTime')} {user.timezone}
                        </span>
                      </>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                </Times>
              )}
            </Preview>
            {alertPastDay && (
              <HolidayLabel>
                Attention: Date(s) you have selected occur in the past
              </HolidayLabel>
            )}
            {(alertWeekend > 0 || (request === 'WFH' && alertWFH > 0)) && (
              <HolidayLabel>
                {alertWeekend > 0 ? `${alertWeekend} weekend` : ''}
                {request === 'WFH' && alertWeekend > 0 && alertWFH > 0
                  ? ` days and `
                  : ''}
                {request === 'WFH' && alertWFH > 0
                  ? `${alertWFH} existing WFH`
                  : ''}{' '}
                {request === 'WFH' && alertWFH === 1 ? 'day has' : 'days have'}{' '}
                been omitted from your request
              </HolidayLabel>
            )}
            {alertHoliday > 0 && (
              <HolidayLabel>
                {alertHoliday} paid{' '}
                {alertHoliday === 1 ? 'holiday has' : 'holidays have'} been
                omitted from your request
              </HolidayLabel>
            )}
          </Steps>
          <Steps className={'right'}>
            <StepLabel>Step 3</StepLabel>
            <Title>Enter Request Details</Title>
            <Input
              name="name"
              label="Employee Name"
              required={true}
              value={user.name}
              disabled={true}
              error={methods.formState.errors?.name}
            />
            <TextArea
              name="notes"
              label="Add Additional Notes"
              description="Please do not include sensitive personal information in this field (e.g., medical)"
              optional={true}
              error={methods.formState.errors?.notes}
              value={methods.getValues().notes}
            />
            <SubmitButton
              primary={true}
              type="submit"
              aria-label="submit"
              className={classNames({
                disabled:
                  errors.missingDates ||
                  errors.request ||
                  errors.startTime ||
                  errors.time ||
                  errors.sameTime ||
                  errors.wfhShort ||
                  errors.wfhLong ||
                  request === '' ||
                  !validDates.start ||
                  !validDates.end ||
                  startDate === '' ||
                  endDate === '' ||
                  (methods.watch('partialDay') && !methods.watch('startTime')),
                submitting: isSubmitting,
              })}
            >
              {isSubmitting ? (
                <SubmitLoader>
                  <PacmanLoader color="#ffffff" />
                </SubmitLoader>
              ) : (
                'Submit Request'
              )}
            </SubmitButton>
            {(errors.date ||
              errors.end ||
              errors.endMissing ||
              errors.missingDates ||
              errors.request ||
              errors.start ||
              errors.startMissing ||
              errors.time ||
              errors.sameTime ||
              errors.startTime ||
              errors.wfhShort ||
              errors.wfhLong ||
              !validDates.start ||
              !validDates.end) && (
              <Error className="right">Please see errors</Error>
            )}
          </Steps>
        </Content>
      </FormProvider>
      <Submission className={classNames('submission', { active: submitted })}>
        <H3>Your request has been successfully submitted for approval.</H3>
        <Preview>
          <Label>Your Request:</Label>
          <Request>{request}</Request>
          <Dates
            className={classNames({
              hasData: startDate || endDate,
            })}
          >
            <span>{startDate || 'Select Date(s) Above'}</span>
            {((!startDate && !endDate) || startDate !== endDate) && (
              <>
                <Dot />
                <span>{endDate || 'Select Date(s) Above'}</span>
              </>
            )}
          </Dates>
          {(methods.watch('startTime') || methods.watch('endTime')) && (
            <Times
              className={classNames({
                hasData: methods.watch('startTime') || methods.watch('endTime'),
              })}
            >
              {methods.watch('startTime') ? (
                <span>
                  {methods.watch('startTime')} {user.timezone}
                </span>
              ) : (
                ''
              )}
              {methods.watch('endTime') ? (
                <>
                  <Dot />
                  <span>
                    {methods.watch('endTime')} {user.timezone}
                  </span>
                </>
              ) : (
                ''
              )}
            </Times>
          )}
          {methods.watch('notes') && (
            <>
              <span className="eyebrow">Notes</span>
              <p className="notes">{methods.watch('notes')}</p>
            </>
          )}
          <AnotherOne
            type="button"
            onClick={() => {
              handleReset();
            }}
          >
            Create Another Request <IconArrow />
          </AnotherOne>
        </Preview>
      </Submission>
    </Container>
  );
};

export default PTOView;
