import { IconPrinter } from 'components/IconsView';
import HolidaysI, { YearI } from 'data/types/Holidays.types';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import YearFilter from './Components/YearFilter';
import {
  Container,
  Content,
  DisclaimerItem,
  DisclaimerList,
  HolidayItem,
  HolidayList,
  NotFound,
  PrinterButton,
  YearHeader,
} from './styles';

interface Props {
  data: YearI[];
}

export const HolidaysView = ({ data }: Props): JSX.Element => {
  const [activeYear, setActiveYear] = useState<string | null>(null);
  const [activeDates, setActiveDates] = useState<HolidaysI[]>([]);
  const [disclaimers, setDisclaimers] = useState<string[]>([]);

  const yearFormat = useMemo(() => {
    return data
      ?.slice()
      .sort((x: YearI, y: YearI) => {
        if (dayjs(x.date).isAfter(dayjs(y.date))) {
          return -1;
        } else if (dayjs(x.date).isBefore(dayjs(y.date))) {
          return 1;
        }
        return 0;
      })
      .map((item: YearI) => {
        return {
          label: item.title,
          value: item.id,
        };
      });
  }, [data]);

  useEffect(() => {
    if (yearFormat) {
      setActiveYear(
        yearFormat.find((x) => x.label === dayjs().format('YYYY'))?.value ||
          yearFormat[0].value
      );
    }
  }, [yearFormat]);

  useEffect(() => {
    if (activeYear) {
      const filterHolidays = data.find((x) => x.id === activeYear);
      let holidays = filterHolidays?.holidays?.items.slice().sort((x, y) => {
        if (dayjs(x.startDate).isAfter(dayjs(y.startDate))) {
          return 1;
        } else if (dayjs(x.startDate).isBefore(dayjs(y.startDate))) {
          return -1;
        }
        return 0;
      });

      // add disclaimer number
      const tempDisclaimers: string[] = [];
      holidays = holidays?.map((holiday) => {
        if (holiday.disclaimer) {
          const newSup = tempDisclaimers.findIndex(
            (x) => x === holiday.disclaimer
          );
          if (newSup === -1) {
            tempDisclaimers.push(holiday.disclaimer);
            setDisclaimers(tempDisclaimers);
            return {
              ...holiday,
              disclaimerNumber: `${tempDisclaimers.length}`,
            };
          }
          return { ...holiday, disclaimerNumber: `${newSup + 1}` };
        }
        return holiday;
      });
      setDisclaimers(tempDisclaimers);
      setActiveDates(holidays || []);
    }
    // eslint-disable-next-line
  }, [activeYear, data]);

  const renderHoliday = (item: HolidaysI): JSX.Element => {
    let dateFormat = '';
    if (
      (item.startDate && !item.endDate) ||
      dayjs(item.startDate).format('M D YY') ===
        dayjs(item.endDate).format('M D YY')
    ) {
      dateFormat = dayjs(item.startDate).format('M/D');
    } else {
      dateFormat = `${dayjs(item.startDate).format('M/D')} - ${dayjs(
        item.endDate
      ).format('M/D')}`;
    }

    return (
      <HolidayItem key={item.id}>
        <span>{dateFormat}</span>
        <span>
          {item.title}
          {item.disclaimerNumber && <sup>{item.disclaimerNumber}</sup>}
        </span>
      </HolidayItem>
    );
  };

  return (
    <Container>
      {yearFormat && (
        <YearFilter
          years={yearFormat}
          defaultActive={
            yearFormat.find((x) => x.label === dayjs().format('YYYY')) ||
            yearFormat[0]
          }
          handleActive={(year) => {
            if (year !== activeYear) {
              setActiveYear(year);
              setDisclaimers([]);
            }
          }}
        />
      )}
      {activeDates.length > 0 ? (
        <Content>
          <YearHeader>
            {data.find((x) => x.id === activeYear)?.title} Holiday Dates
          </YearHeader>
          <HolidayList>
            {activeDates.map((item: HolidaysI) => {
              return renderHoliday(item);
            })}
          </HolidayList>
          {disclaimers.length > 0 && (
            <DisclaimerList>
              {disclaimers.map((item, i) => {
                return (
                  <DisclaimerItem key={`${i}`}>
                    <span>
                      <sup>{i + 1}</sup>
                      {item}
                    </span>
                  </DisclaimerItem>
                );
              })}
            </DisclaimerList>
          )}
          <PrinterButton
            className="print-button"
            tertiary
            onClick={() => window.print()}
          >
            <IconPrinter /> Print Schedule
          </PrinterButton>
        </Content>
      ) : (
        <NotFound>
          Holiday dates not yet available for{' '}
          {data.find((x) => x.id === activeYear)?.title}.
        </NotFound>
      )}
    </Container>
  );
};

export default HolidaysView;
